exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-ecommerce-js": () => import("./../../../src/pages/industries-ecommerce.js" /* webpackChunkName: "component---src-pages-industries-ecommerce-js" */),
  "component---src-pages-industries-manufacturing-js": () => import("./../../../src/pages/industries-manufacturing.js" /* webpackChunkName: "component---src-pages-industries-manufacturing-js" */),
  "component---src-pages-industries-professional-services-js": () => import("./../../../src/pages/industries-professional-services.js" /* webpackChunkName: "component---src-pages-industries-professional-services-js" */),
  "component---src-pages-industries-retail-js": () => import("./../../../src/pages/industries-retail.js" /* webpackChunkName: "component---src-pages-industries-retail-js" */),
  "component---src-pages-industries-startups-js": () => import("./../../../src/pages/industries-startups.js" /* webpackChunkName: "component---src-pages-industries-startups-js" */),
  "component---src-pages-microsoft-dynamics-365-consultation-services-js": () => import("./../../../src/pages/microsoft-dynamics-365-consultation-services.js" /* webpackChunkName: "component---src-pages-microsoft-dynamics-365-consultation-services-js" */),
  "component---src-pages-microsoft-dynamics-365-customization-services-js": () => import("./../../../src/pages/microsoft-dynamics-365-customization-services.js" /* webpackChunkName: "component---src-pages-microsoft-dynamics-365-customization-services-js" */),
  "component---src-pages-microsoft-dynamics-365-maintenance-support-services-js": () => import("./../../../src/pages/microsoft-dynamics-365-maintenance-support-services.js" /* webpackChunkName: "component---src-pages-microsoft-dynamics-365-maintenance-support-services-js" */),
  "component---src-pages-microsoft-dynamics-365-upgrade-services-js": () => import("./../../../src/pages/microsoft-dynamics-365-upgrade-services.js" /* webpackChunkName: "component---src-pages-microsoft-dynamics-365-upgrade-services-js" */),
  "component---src-pages-microsoft-dynamics-implementation-services-js": () => import("./../../../src/pages/microsoft-dynamics-implementation-services.js" /* webpackChunkName: "component---src-pages-microsoft-dynamics-implementation-services-js" */),
  "component---src-pages-microsoft-dynamics-integration-services-js": () => import("./../../../src/pages/microsoft-dynamics-integration-services.js" /* webpackChunkName: "component---src-pages-microsoft-dynamics-integration-services-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */)
}

